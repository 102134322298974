import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Testimonial from "../sections/index/indexreviews.js";
import Contentchin from "../sections/structura/struccontent.js";
import ChinFaq from "../sections/structura/strucfaq.js";
import Head from "../components/head"
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/temple.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PopupForm from "../components/PopupForm/PopupForm"

const ProfStruc = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
    <Head title="Profhilo Strutura Treatment in London" image="/images/dermamina-home.jpg"  description="Restore facial volume and lift with Profhilo Structura – a unique injectable that rejuvenates skin by targeting superficial fat pads for natural support." keywords="Profhilo Structura, facial volume restoration, skin laxity treatment, anti-ageing injectable, mid-face lift, adipose tissue rejuvenation, non-surgical facelift, skin tightening, collagen booster, facial contouring"/>
<PageWrapper headerDark>
  <PopupForm
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
  />

<HeroComponent
    itext='<span style="color: #1a1a1a;">Profhilo </span> <span style="color: #00aec7;">Structura</span>'
    secondText="Feel structured. Look refreshed."
    videoAlt="Structura"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />
  

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="3 Sessions"
  bestalt="best Polynucleotides Aessions"
  resultstext="4-6 months"
  resultsalt="Polynucleotides results"
  costdata="£350"
  costalt="Polynucleotides Cost"
  timedata="20 Minutes"
  timealt="Polynucleotides Duration Time"
  workdata="Immediately"
  workalt="Polynucleotides downtime work"
  paindata="Mild"
  painalt="Polynucleotides Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
  <Contentchin
    setIsModalOpen={setIsModalOpen}
  />
 
<Award />
<Testimonial />

<ChinFaq
  setIsModalOpen={setIsModalOpen}
/>
  
<Clinic /> 
<Saveface />
<Trend />
</PageWrapper>

    </>
  );
};
export default ProfStruc;
