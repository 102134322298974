

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";

import Newarrow from '../../assets/image/jpeg/enter.svg';




const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;



const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function Polynucleotidesfaq({setIsModalOpen}) {
    return (
        <Section py={4} id="faq" bg="#ffffff" className="pb-md-5 mb pt-5 mt-2 pt-md-5">
            <Container className="pb-md-5 mb pt-md-5">
                <Row className="justify-content-center">
                    <Col lg="12">

                        <Iwrap>


                            <Itext as="h2">Questions about <span>Profhilo Structura</span></Itext>
                        </Iwrap>
                        <SecondText>
                            Frequently Asked Questions

                        </SecondText>

                    </Col>
                </Row>


                <Row className="pt-5">


                    <Col
                      lg="12"
                      md="6"
                      className="mb-4"
                      data-aos="fade-up"
                      data-aos-duration="750"
                      data-aos-once="false"
                      data-aos-delay="50"
                    >


                      
<Accordion allowZeroExpanded className="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton className="text-[14px] pl-2">
                How long do results last?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Results tend to last 6 months, however, results cannot be guaranteed as this depends on individual lifestyle factors such as medications, smoking, and sun exposure.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion>
<br />

<Accordion allowZeroExpanded className="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton className="text-[14px] pl-2">
                How often do I need a session?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                We advise a course of 2-3 sessions depending on your skin’s current condition, spaced 4 weeks apart.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion>
<br />

<Accordion allowZeroExpanded className="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton className="text-[14px] pl-2">
                What does Profhilo Structura feel like? Is it painful?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                We apply numbing cream 15–30 minutes before the treatment to ensure comfort. The injection may feel like a dull ache lasting a few seconds.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion>
<br />

<Accordion allowZeroExpanded className="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton className="text-[14px] pl-2">
                What can I expect during the healing process?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                It's common to experience slight redness, mild swelling, and small bumps at the injection site that usually resolve within 24–48 hours. Tenderness may occur, especially when touched or with facial expressions. Bruising can occur and usually fades within 5–14 days. Arnica cream can help with recovery. Avoid medications like aspirin, warfarin, or certain supplements 48 hours prior as they may increase bruising risk.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion>
<br />

<Accordion allowZeroExpanded className="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton className="text-[14px] pl-2">
                Can I apply make up after the treatment?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                No, however, you may apply makeup 24 hours after the treatment.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion>
<br />

<Accordion allowZeroExpanded className="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton className="text-[14px] pl-2">
                Can it be injected alongside Profhilo?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Yes, it can be injected in combination with Profhilo Face, as they work on different layers and achieve different outcomes. We offer a package for this.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion>
<br />

<Accordion allowZeroExpanded className="">
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton className="text-[14px] pl-2">
                Is there down time?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Overall, the healing period can last up to 2 weeks.
            </p>
        </AccordionItemPanel>
    </AccordionItem>
</Accordion>
<br />





                    </Col>


                </Row>


                <div onClick={() => setIsModalOpen(true)}
                     activeClassName="active"
                >
                    <Bookbutton>
                        Book Appointment
                    </Bookbutton>
                </div>


            </Container>
        </Section>


    );
}







