import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/polycontent.jpg";

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const PolyContent = ({setIsModalOpen}) => (
  <>
  <Separator />
    {/* <!-- Content section 2 --> */}
    <Section bg="#f7f7fb" py={4}>
      <Container className="pt-3 pb-3">

          <Row className="pb-4">
              <Col>
                <Iwrap>


<Itext as="h2">Profhilo Structura <span>London</span></Itext>
</Iwrap>
      <SecondText>
      What is Profhilo Structura?

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className=""
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img src={imgMobile} alt="polynucleotides" className="shadow rounded" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">

              <Text>


              When we age we experience a change in our muscles, adipose tissue,
skeletal bones, ligaments,and skin. Profhilo STRUCTURA is injected into the
superficial fat pads for biomodulating the adipose tissues of the face that are
lax.   <br />   <br />
 
It is is specifically formulated to work alongside superficial adipose tissue by
restoring the superficial fat. Profhilo® Structura addresses age-related
concerns such as hollowing in the face and skin laxity of which are clinically
proven.   <br />   <br /> Profhilo® Structura triggers ageing adipose fat cell receptors, this
encourages them to support the laxity of the skin and soft tissues the same
way as when they were young. According to studies, 95% of patients noticed
long-term enhancements in the mid-face region of the face after their first
treatment.   <br />   <br />

At Dermamina, a numbing cream is applied 15-30 minutes before your
treatment to ensure minimal discomfort. After being numbed and signing the
necessary forms, you will meet with the practitioner in the treatment room. All
procedures begin with a consultation prior to starting the treatment itself.
During a Profhilo STRUCTURA treatment, you may experience a mild, dull
aching sensation as the product is injected into the fat layer. This discomfort is
brief and generally well-tolerated by most clients.    <br />   <br /> If you feel any discomfort,
please inform your practitioner so they can pause or adjust the treatment as
needed. The practitioner will use cannula for the treatment. Results are not
immediate; you will notice gradual improvement within 7-14 days after the first
treatment, with cumulative results becoming evident one month after the
second treatment.


              </Text>


              <br />
              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active"
              >
                <Bookbutton>
                  Book Appointment
                </Bookbutton>
              </div>

            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
    <SeparatorEnd />
  </>
);

export default PolyContent;
